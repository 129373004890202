import React from 'react';
import renderHTML from 'htmr';
import OutsideClickHandler from 'react-outside-click-handler';

import icon_testimonial from '../../images/icon-testimonial.svg';

export default function TestimonialDetailPopup({ testimonial, toggleModal }) {
  const description = testimonial?.data?.description;
  const featuredImage = testimonial?.data?.featuredImage?.url;
  const title = testimonial?.data?.title[0]?.text;
  const position = testimonial?.data?.position[0]?.text;
  const totalDescription = description?.map((textObj) => textObj.text).join('\n');

  return (
    <div className={`popup popup-center-xy popup-access popup-login ${testimonial ? 'show-modal' : ''}`}>
      <OutsideClickHandler onOutsideClick={() => toggleModal(null)}>
        <div className="wrapper wrapper-xs center-xy">
          <div className="nav-bars">
            <button
              type="button"
              className="modal-close-button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => toggleModal(null)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="testimonial-item">
            <div className="item t_item hover-none">
              <div className="t_figure">
                <img src={icon_testimonial} alt="Testimonial Icon" className="icon-testimonial" />
              </div>
              {testimonial && (
                <>
                  <div className="t_content">{renderHTML(totalDescription)}</div>
                  <div className="t_author d-flex">
                    {featuredImage && (
                      <div
                        className="t_a_figure bgImage"
                        style={{
                          backgroundImage: `url(${featuredImage})`,
                        }}
                      />
                    )}
                    <div className="t_a_description">
                      <span className="t_a_name">{title}</span>
                      {position && <span className="t_a_post">{position}</span>}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
}
